import { useState } from 'react';
import { VStack } from '../../shared/components/layout/VStack';
import { FormSectionContent } from './FormSectionContent';
import { FormSectionPicker } from './FormSectionPicker';

export type FormSectionType = 'Register' | 'Login';

export function FormSection() {
  const [openSection, setOpenSection] = useState<FormSectionType>('Register');

  return (
    <VStack flexGrow={1} gap={1}>
      <FormSectionPicker setSection={setOpenSection} />
      <FormSectionContent section={openSection} />
    </VStack>
  );
}
