import { CSSProperties } from 'react';

export type DividerProps = Partial<CSSProperties>;

export function Divider({ ...props }: DividerProps) {
  return (
    <div
      style={{
        ...props,
        width: 0,
        border: 'solid 1px',
        borderRadius: '10px',
      }}
    ></div>
  );
}
