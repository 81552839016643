import { Card } from '../../shared/components/Card';
import { TextParagraph } from '../../shared/components/text/TextParagraph';
import { ColorPalette } from '../../utils/colorPalette';
import { GameOverWindow } from './GameOverWindow';
import { GameStartWindow } from './GameStartWindow';
import { GameWindow } from './GameWindow';

export type GameSectionType = {
  hasStarted: boolean;
  setGameHasStarted: (hasStarted: boolean) => void;
  hasEnded: boolean;
  setGameHasEnded: (hasEnded: boolean) => void;
  currentScore: number;
  setCurrentScore: (newScore: number) => void;
  highScore: number;
  setHighScore: (newHighScore: number) => void;
  errorMessage: string;
};

export function GameSection({
  hasStarted,
  setGameHasStarted,
  hasEnded,
  setGameHasEnded,
  currentScore,
  setCurrentScore,
  highScore,
  setHighScore,
  errorMessage,
}: GameSectionType) {
  return (
    <Card backgroundColor={ColorPalette.primaryLight}>
      {errorMessage ? (
        <TextParagraph color={ColorPalette.primaryText} title={errorMessage} />
      ) : !hasStarted ? (
        <GameStartWindow setHasStarted={setGameHasStarted} />
      ) : !hasEnded ? (
        <GameWindow
          currentScore={currentScore}
          setCurrentScore={setCurrentScore}
          highScore={highScore}
          setHighScore={setHighScore}
          sethasGameEnded={setGameHasEnded}
        />
      ) : (
        <GameOverWindow
          score={currentScore}
          highScore={highScore}
          setScore={setCurrentScore}
          setHighScore={setHighScore}
          setGameHasStarted={setGameHasStarted}
          setGameHasEnded={setGameHasEnded}
        />
      )}
    </Card>
  );
}
