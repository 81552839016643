import { CSSProperties, memo, PropsWithChildren } from 'react';
import { ColorPalette } from '../../../utils/colorPalette';

export type TableRowProps = Partial<CSSProperties>;

export const TableRow = memo(
  ({ children, ...props }: PropsWithChildren<TableRowProps>) => {
    return (
      <tr
        style={{
          borderColor: ColorPalette.secondary,
          borderRadius: '5px',
          ...props,
        }}
      >
        {children}
      </tr>
    );
  }
);
