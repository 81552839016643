import { CSSProperties, memo, PropsWithChildren } from 'react';
import { ColorPalette } from '../../../utils/colorPalette';

export type TableBodyProps = Partial<CSSProperties>;

export const TableBody = memo(
  ({ children, ...props }: PropsWithChildren<TableBodyProps>) => {
    return (
      <thead
        style={{
          backgroundColor: ColorPalette.primaryLight,
          color: ColorPalette.primaryText,
          borderColor: ColorPalette.secondary,
          borderRadius: '5px',
          ...props,
        }}
      >
        {children}
      </thead>
    );
  }
);
