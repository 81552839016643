import { useEffect, useState } from 'react';
import { VStack } from '../../shared/components/layout/VStack';
import { GameSectionPicker } from './GameSectionPicker';
import * as api from '../../api/api';
import { LeaderboardSection } from './LeaderboardSection';
import { GameSection } from './GameSection';
import { useUser } from '../../contexts/UserContext';
import { Button } from '../../shared/components/buttons/Button';
import { TextParagraph } from '../../shared/components/text/TextParagraph';

export type GameSectionType = 'Play' | 'Leaderboard';

export type Leaderboard = {
  _id: string;
  username?: string;
  highScore?: number;
}[];

export function Game() {
  const userContext = useUser();

  const [openSection, setOpenSection] = useState<GameSectionType>('Play');

  const [leaderboard, setLeaderboard] = useState<Leaderboard>([]);
  const [leaderboardErrorMessage, setLeaderboardErrorMessage] = useState('');

  const [gameStarted, setGameStarted] = useState(false);
  const [gameEnded, setGameEnded] = useState(false);
  const [currentScore, setCurrentScore] = useState(0);
  const [highScore, setHighScore] = useState(0);
  const [gameErrorMessage, setGameErrorMessage] = useState('');

  useEffect(() => {
    setLeaderboardErrorMessage('');
    const fetchLeaderboard = async () => {
      try {
        const data = await api.getLeaderboard(10);
        setLeaderboard(data);
      } catch (err) {
        setLeaderboardErrorMessage(String(err));
      }
    };
    fetchLeaderboard();
  }, [highScore]);

  useEffect(() => {
    const fetchHighScore = async () => {
      try {
        const username = userContext?.username;
        if (!username) {
          throw Error('Something went wrong!');
        }
        const data = await api.getUser(username);
        setHighScore(data.highScore ?? 0);
      } catch (err) {
        setGameErrorMessage(String(err));
      }
    };
    fetchHighScore();
  }, [userContext]);

  const handleLogout = () => {
    if (userContext) {
      userContext.updateUserContext(undefined);
    }
  };

  return (
    <VStack flexGrow={1} gap={1}>
      <GameSectionPicker setSection={setOpenSection} />
      {openSection === 'Play' ? (
        <GameSection
          hasStarted={gameStarted}
          setGameHasStarted={setGameStarted}
          hasEnded={gameEnded}
          setGameHasEnded={setGameEnded}
          currentScore={currentScore}
          setCurrentScore={setCurrentScore}
          highScore={highScore}
          setHighScore={setHighScore}
          errorMessage={gameErrorMessage}
        />
      ) : (
        <LeaderboardSection
          leaderboard={leaderboard}
          errorMessage={leaderboardErrorMessage}
        />
      )}
      <Button onPress={handleLogout} variant="secondary">
        <TextParagraph title="Logout" />
      </Button>
    </VStack>
  );
}
