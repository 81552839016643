import './App.css';
import { Main } from './shared/components/layout/Main';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { HomePage } from './components/HomePage';
import { GamePage } from './components/GamePage';
import { useUser } from './contexts/UserContext';

export type ProtectedRouteProps = {
  element: JSX.Element;
  fallBackPath: string;
};

function ProtectedRoute({ element, fallBackPath }: ProtectedRouteProps) {
  const userContext = useUser();
  if (userContext && userContext.username) {
    return element;
  }
  return <Navigate to={fallBackPath} />;
}

function App() {
  return (
    <Router>
      <Main>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/game"
            element={
              <ProtectedRoute element={<GamePage />} fallBackPath={'/'} />
            }
          />
        </Routes>
      </Main>
    </Router>
  );
}

export default App;
