import { useState, ChangeEvent } from 'react';
import { ColorPalette } from '../../../utils/colorPalette';
import { Button } from '../buttons/Button';
import { Card } from '../Card';
import { VStack } from '../layout/VStack';
import { TextInput } from '../text/TextInput';
import { TextParagraph } from '../text/TextParagraph';
import * as api from '../../../api/api';

export function RegisterForm() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleInputChange = (
    setState: React.Dispatch<React.SetStateAction<string>>
  ) => {
    return function (e: ChangeEvent<HTMLInputElement>) {
      setState(e.target.value);
    };
  };

  const handleSubmit = async () => {
    setMessage('');
    try {
      await api.registerUser(username, password);
      setMessage('Registration Successful!');
    } catch (err: any) {
      setMessage(String(err));
    }
  };

  return (
    <Card backgroundColor={ColorPalette.primaryLight}>
      <VStack gap={1}>
        <TextInput
          value={username}
          onChange={handleInputChange(setUsername)}
          variant={'text'}
          label="Username"
          maxWidth={'15vw'}
        />
        <TextInput
          value={password}
          onChange={handleInputChange(setPassword)}
          variant={'password'}
          label="Password"
          maxWidth={'15vw'}
        />
        {message ? (
          <TextParagraph color={ColorPalette.primaryText} title={message} />
        ) : null}
        <Button onPress={handleSubmit} variant="primary" marginTop={'5px'}>
          <TextParagraph title="Register" />
        </Button>
      </VStack>
    </Card>
  );
}
