import { CSSProperties, memo, PropsWithChildren } from 'react';
import { ColorPalette } from '../../../utils/colorPalette';

export type TableHeaderProps = Partial<CSSProperties>;

export const TableHeader = memo(
  ({ children, ...props }: PropsWithChildren<TableHeaderProps>) => {
    return (
      <thead
        style={{
          backgroundColor: ColorPalette.primaryText,
          color: ColorPalette.primaryLight,
          borderColor: ColorPalette.secondary,
          borderRadius: '5px',
          ...props,
        }}
      >
        {children}
      </thead>
    );
  }
);
