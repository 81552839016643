import { Card } from '../shared/components/Card';
import { Divider } from '../shared/components/layout/Divider';
import { HStack } from '../shared/components/layout/HStack';
import { VStack } from '../shared/components/layout/VStack';
import { TextHeader } from '../shared/components/text/TextHeader';
import { TextParagraph } from '../shared/components/text/TextParagraph';
import { FormSection } from './FormSection/FormSection';

export function HomePage() {
  return (
    <Card marginTop={'10%'}>
      <HStack flexGrow={1} gap={1}>
        <VStack flexGrow={1} gap={1} maxWidth={'60%'}>
          <TextHeader title="quick math." />
          <TextParagraph
            title={`quick math is an online game which tests your mathematical skills.`}
          />
          <TextParagraph title="the player is asked math questions and they must provide the correct answer. for each correct answer the player earns 1 point. the game ends when the player answers incorrectly." />
          <TextParagraph title="since i hate division (and totally not because of decimal places), you will only be asked addition, subtraction and multiplcation problems" />
          <TextParagraph title="good luck and have fun :)" />
        </VStack>
        <Divider></Divider>
        <FormSection />
      </HStack>
    </Card>
  );
}
