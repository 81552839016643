import { CSSProperties, memo, PropsWithChildren } from 'react';
import { ColorPalette } from '../../../utils/colorPalette';

export type MainProps = Partial<CSSProperties>;

export const Main = memo(
  ({ children, ...props }: PropsWithChildren<MainProps>) => {
    return (
      <div
        style={{
          ...props,
          backgroundColor: ColorPalette.primaryLight,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}
      >
        <div
          style={{
            width: '80%',
            height: '100vh',
          }}
        >
          <>{children}</>
        </div>
      </div>
    );
  }
);
