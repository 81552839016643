import { CSSProperties, memo, PropsWithChildren } from 'react';
import { ColorPalette } from '../../../utils/colorPalette';

export type TableCellProps = Partial<CSSProperties>;

export const TableCell = memo(
  ({ children, ...props }: PropsWithChildren<TableCellProps>) => {
    return (
      <td
        style={{
          borderColor: ColorPalette.secondary,
          borderRadius: '5px',
          padding: '5px 1vw',
          ...props,
        }}
      >
        {children}
      </td>
    );
  }
);
