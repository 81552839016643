import { SectionPicker } from '../../shared/components/SectionPicker';
import { GameSectionType } from './Game';

export type GameSectionPickerProps = {
  setSection: (section: GameSectionType) => void;
};

export function GameSectionPicker({ setSection }: GameSectionPickerProps) {
  return (
    <SectionPicker
      sections={[
        {
          name: 'Play',
          setSection: setSection,
        },
        {
          name: 'Leaderboard',
          setSection: setSection,
        },
      ]}
    />
  );
}
