import { ChangeEvent, useState } from 'react';
import { ColorPalette } from '../../../utils/colorPalette';
import { Button } from '../buttons/Button';
import { Card } from '../Card';
import { VStack } from '../layout/VStack';
import { TextInput } from '../text/TextInput';
import { TextParagraph } from '../text/TextParagraph';
import * as api from '../../../api/api';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../contexts/UserContext';

export function LoginForm() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigator = useNavigate();

  const userContext = useUser();
  if (!userContext) {
    throw Error('Something went wrong!');
  }

  const handleInputChange = (
    setState: React.Dispatch<React.SetStateAction<string>>
  ) => {
    return function (e: ChangeEvent<HTMLInputElement>) {
      setState(e.target.value);
    };
  };

  const handleSubmit = async () => {
    setMessage('');
    try {
      const data = await api.loginUser(username, password);
      const { username: user } = data;
      if (!user) {
        throw Error('Something went wrong!');
      }
      userContext.updateUserContext(user);
      navigator('/game');
    } catch (err: any) {
      setMessage(String(err));
    }
  };

  return (
    <Card backgroundColor={ColorPalette.primaryLight}>
      <VStack gap={1}>
        <TextInput
          value={username}
          onChange={handleInputChange(setUsername)}
          variant={'text'}
          label="Username"
          maxWidth={'15vw'}
        />
        <TextInput
          value={password}
          onChange={handleInputChange(setPassword)}
          variant={'password'}
          label="Password"
          maxWidth={'15vw'}
        />
        {message ? (
          <TextParagraph color={ColorPalette.primaryText} title={message} />
        ) : null}
        <Button onPress={handleSubmit} variant="primary" marginTop={'5px'}>
          <TextParagraph title="Login" />
        </Button>
      </VStack>
    </Card>
  );
}
