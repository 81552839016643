import { CSSProperties, memo } from 'react';
import { ColorPalette } from '../../../utils/colorPalette';

export type TextHeaderProps = {
  title: string;
} & Partial<CSSProperties>;

export const TextHeader = memo(({ title, ...props }: TextHeaderProps) => {
  return (
    <div
      style={{
        color: ColorPalette.primaryText,
        ...props,
      }}
    >
      <h1>{title}</h1>
    </div>
  );
});
