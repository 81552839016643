export enum ColorPalette {
  'primary' = '#E85C0D',
  'secondary' = '#821131',
  'primaryForeground' = '#FABC3F',
  'primaryBackground' = '#E85C0D',
  'primaryText' = '#821131',
  'secondaryText' = '#FFEEAD',
  'primaryLight' = '#FFEEAD',
  'secondaryLight' = 'grey',
  'disabled' = 'darkgrey',
}
