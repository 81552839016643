import { CSSProperties, memo, PropsWithChildren, useState } from 'react';
import { ColorPalette } from '../../../utils/colorPalette';

export type ButtonProps = {
  variant: 'primary' | 'secondary';
  onPress?: () => void;
} & Partial<CSSProperties>;

export const Button = memo(
  ({
    variant,
    children,
    onPress,
    ...props
  }: PropsWithChildren<ButtonProps>) => {
    const [backgroundColor, setBackgroundColor] = useState(
      variant === 'primary' ? ColorPalette.primary : ColorPalette.secondary
    );
    return (
      <div
        style={{
          ...props,
        }}
      >
        <button
          style={{
            padding: '0.5vh 1vw',
            backgroundColor,
            color: ColorPalette.primaryLight,
            cursor: 'pointer',
            border: 'none',
            borderRadius: '10px',
            boxShadow: '0 6px 10px rgba(0, 0, 0, 0.2)',
          }}
          onMouseDown={() => {
            setBackgroundColor(
              variant === 'primary'
                ? ColorPalette.primaryForeground
                : ColorPalette.primaryForeground
            );
          }}
          onMouseUp={() => {
            setBackgroundColor(
              variant === 'primary'
                ? ColorPalette.primary
                : ColorPalette.secondary
            );
          }}
          onClick={onPress}
        >
          {children}
        </button>
      </div>
    );
  }
);
