import { CSSProperties, memo, PropsWithChildren } from 'react';

export type TableProps = Partial<CSSProperties>;

export const Table = memo(
  ({ children, ...props }: PropsWithChildren<TableProps>) => {
    return (
      <table
        style={{
          ...props,
        }}
      >
        {children}
      </table>
    );
  }
);
