import {
  CSSProperties,
  HTMLInputTypeAttribute,
  PropsWithChildren,
  InputHTMLAttributes,
  ChangeEvent,
  memo,
} from 'react';
import { ColorPalette } from '../../../utils/colorPalette';
import { VStack } from '../layout/VStack';

export type TextInputProps = {
  label: string;
  value?: string | number;
  variant: HTMLInputTypeAttribute;
  placeholder?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
} & Partial<Omit<InputHTMLAttributes<number>, 'onChange' | 'value'>> &
  Partial<Omit<CSSProperties, 'gap'>>;

export const TextInput = memo(
  ({
    label,
    value,
    variant,
    placeholder,
    children,
    onChange,
    ...props
  }: PropsWithChildren<TextInputProps>) => {
    return (
      <VStack gap={2} {...props}>
        <p
          style={{
            color: ColorPalette.primaryText,
          }}
        >
          {label}
        </p>
        <input
          type={variant}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          style={{
            padding: '10px 1vw',
            border: 'solid grey 1px',
            borderRadius: '5px',
          }}
        ></input>
      </VStack>
    );
  }
);
