import { ChangeEvent, useState } from 'react';
import { Button } from '../../shared/components/buttons/Button';
import { Divider } from '../../shared/components/layout/Divider';
import { HStack } from '../../shared/components/layout/HStack';
import { TextHeader } from '../../shared/components/text/TextHeader';
import { TextInput } from '../../shared/components/text/TextInput';
import { TextParagraph } from '../../shared/components/text/TextParagraph';
import { ColorPalette } from '../../utils/colorPalette';

type QuestionBoxProps = {
  score: number;
  setScore: (score: number) => void;
  setHasGameEnded: (hasEnded: boolean) => void;
};

export function QuestionBox({
  score,
  setScore,
  setHasGameEnded,
}: QuestionBoxProps) {
  const possibleOperations = ['+', '-', 'x'];

  const [firstNumber, setFirstNumber] = useState(
    Math.floor(Math.random() * 10)
  );
  const [secondNumber, setSecondNumber] = useState(
    Math.floor(Math.random() * 10)
  );
  const [operation, setOperation] = useState(
    possibleOperations[Math.floor(Math.random() * 3)]
  );

  const [playerAnswer, setPlayerAnswer] = useState(0);

  const refreshQuestion = () => {
    setFirstNumber(Math.floor(Math.random() * 10));
    setSecondNumber(Math.floor(Math.random() * 10));
    setOperation(possibleOperations[Math.floor(Math.random() * 3)]);
  };

  const handleSubmit = () => {
    let answer;
    if (operation === '+') {
      answer = firstNumber + secondNumber;
    } else if (operation === '-') {
      answer = firstNumber - secondNumber;
    } else {
      answer = firstNumber * secondNumber;
    }
    if (playerAnswer === answer) {
      setScore(score + 1);
      refreshQuestion();
    } else {
      setHasGameEnded(true);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPlayerAnswer(Number(e.target.value));
  };

  return (
    <HStack flexGrow={1} gap={2}>
      <TextHeader
        minWidth={'8vw'}
        color={ColorPalette.primaryLight}
        backgroundColor={ColorPalette.primaryText}
        padding={'10px 1vw'}
        borderRadius={'10px'}
        title={`${firstNumber} ${operation} ${secondNumber} = `}
      />
      <HStack gap={2}>
        <TextInput
          variant={'number'}
          label=""
          maxWidth={'100px'}
          onChange={handleInputChange}
        />
        <Button variant="primary" onPress={handleSubmit} marginTop={'27.5px'}>
          <TextParagraph title="Submit"></TextParagraph>
        </Button>
      </HStack>
    </HStack>
  );
}

type ScoreBoxProps = {
  title: string;
  score: number;
  highScore?: number;
};

export function ScoreBox({ title, score, highScore }: ScoreBoxProps) {
  return (
    <TextHeader
      color={ColorPalette.primaryLight}
      backgroundColor={ColorPalette.primaryText}
      padding={'10px 1vw'}
      borderRadius={'10px'}
      title={`${title} : ${Math.max(score, highScore ?? 0)}`}
    ></TextHeader>
  );
}

export type GameWindowProps = {
  currentScore: number;
  setCurrentScore: (newScore: number) => void;
  highScore: number;
  setHighScore: (newHighScore: number) => void;
  sethasGameEnded: (hasEnded: boolean) => void;
};

export function GameWindow({
  currentScore,
  setCurrentScore,
  highScore,
  setHighScore,
  sethasGameEnded,
}: GameWindowProps) {
  return (
    <HStack gap={1}>
      <QuestionBox
        score={currentScore}
        setScore={setCurrentScore}
        setHasGameEnded={sethasGameEnded}
      />
      <Divider></Divider>
      <ScoreBox title="Score" score={currentScore} />
      <Divider></Divider>
      <ScoreBox title="High Score" score={currentScore} highScore={highScore} />
    </HStack>
  );
}
