import { Button } from '../../shared/components/buttons/Button';
import { VStack } from '../../shared/components/layout/VStack';
import { TextHeader } from '../../shared/components/text/TextHeader';
import { ColorPalette } from '../../utils/colorPalette';

type GameStartWindowProps = {
  setHasStarted: (hasStarted: boolean) => void;
};

export function GameStartWindow({ setHasStarted }: GameStartWindowProps) {
  return (
    <VStack gap={1} alignItems={'center'} justifyContent={'center'}>
      <Button
        onPress={() => {
          setHasStarted(true);
        }}
        variant="secondary"
      >
        <TextHeader color={ColorPalette.primaryLight} title="START" />
      </Button>
    </VStack>
  );
}
