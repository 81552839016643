import { CSSProperties, PropsWithChildren, memo } from 'react';

const gapValue = {
  0: '0%',
  1: '2%',
  2: '4%',
  3: '8%',
  4: '16%',
  5: '20%',
  6: '24%',
} as const;

export type HStackProps = {
  gap: 0 | 1 | 2 | 3 | 4 | 5;
} & Partial<Omit<CSSProperties, 'gap'>>;

export const HStack = memo(
  ({ gap, children, ...props }: PropsWithChildren<HStackProps>) => {
    return (
      <div
        style={{
          ...props,
          display: 'flex',
          gap: gapValue[gap],
          flexDirection: 'row',
        }}
      >
        {children}
      </div>
    );
  }
);
