import { CSSProperties, useState } from 'react';
import { ColorPalette } from '../../utils/colorPalette';
import { TextHeader } from './text/TextHeader';

export type Section<T> = {
  name: T;
  setSection: (section: T) => void;
};

export type SectionPickerProps<T> = {
  sections: Section<T>[];
} & Partial<CSSProperties>;

export function SectionPicker<T>({
  sections,
  ...props
}: SectionPickerProps<T>) {
  const [activeSectionIdx, setActiveSectionIdx] = useState(0);

  const handleSectionChange = (name: string, idx: number) => {
    if (activeSectionIdx !== idx) {
      setActiveSectionIdx(idx);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        ...props,
      }}
    >
      {sections.map((section, idx) => {
        return (
          <button
            style={{
              backgroundColor:
                idx === activeSectionIdx
                  ? ColorPalette.primaryLight
                  : ColorPalette.disabled,
              width: '100%',
              cursor: 'pointer',
            }}
            key={idx}
            onClick={() => {
              section.setSection(section.name);
              handleSectionChange(section.name as string, idx);
            }}
          >
            <TextHeader title={section.name as string}></TextHeader>
          </button>
        );
      })}
    </div>
  );
}
