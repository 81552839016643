const API_BASE_URL = process.env.REACT_APP_SERVER_URL;

export type LoginUserResponse = {
  username?: string;
  error?: string;
};

export async function loginUser(username: string, password: string) {
  const requestBody = {
    username,
    password,
  };
  try {
    const resp = await fetch(`${API_BASE_URL}/users/login`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const result = (await resp.json()) as LoginUserResponse;
    if (result.error) {
      throw Error(result.error);
    }

    // store auth token in local storage
    return result;
  } catch (err) {
    throw err;
  }
}

export type RegisterUserResponse = {
  error?: string;
};

export async function registerUser(username: string, password: string) {
  const requestBody = {
    username,
    password,
  };
  try {
    const resp = await fetch(`${API_BASE_URL}/users/register`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const result = (await resp.json()) as RegisterUserResponse;
    if (result.error) {
      throw Error(result.error);
    }
    return result;
  } catch (err) {
    throw err;
  }
}

export type GetLeaderboardResponse = {
  error?: string;
  data?: {
    username?: string;
    highScore?: number;
    _id: string;
  }[];
};

export async function getLeaderboard(top: number) {
  const queryParams = new URLSearchParams({ top: top.toString() });
  try {
    const resp = await fetch(`${API_BASE_URL}/leaderboard/?${queryParams}`);
    const result = (await resp.json()) as GetLeaderboardResponse;
    if (result.error) {
      throw Error(result.error);
    }
    if (!result.data) {
      throw Error('Something went wrong!');
    }
    return result.data;
  } catch (err) {
    throw err;
  }
}

export type GetUserResponse = {
  data?: {
    _id: string;
    username?: string;
    highScore?: number;
  };
  error?: string;
};

export async function getUser(username: string) {
  try {
    const resp = await fetch(`${API_BASE_URL}/users/${username}`);
    const result = (await resp.json()) as GetUserResponse;
    if (result.error) {
      throw Error(result.error);
    }
    if (!result.data) {
      throw Error('Something went wrong!');
    }
    return result.data;
  } catch (err) {
    throw err;
  }
}

export type UpdateUserHighScoreResponse = {
  error?: string;
};

export async function updateUserHighScore(score: number, username: string) {
  const requestBody = {
    highScore: score,
  };
  try {
    const resp = await fetch(`${API_BASE_URL}/users/${username}`, {
      method: 'PUT',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const result = (await resp.json()) as UpdateUserHighScoreResponse;
    if (result.error) {
      throw Error(result.error);
    }
  } catch (err) {
    throw err;
  }
}
