import { CSSProperties, memo, PropsWithChildren } from 'react';
import { ColorPalette } from '../../utils/colorPalette';

export type CardProps = {} & Partial<CSSProperties>;

export const Card = memo(
  ({ children, ...props }: PropsWithChildren<CardProps>) => {
    return (
      <div
        style={{
          background: ColorPalette.primaryBackground,
          border: 'solid 2px',
          borderRadius: '10px',
          boxShadow: '0 6px 10px rgba(0, 0, 0, 0.2)',
          padding: '3vh',
          ...props,
        }}
      >
        {children}
      </div>
    );
  }
);
