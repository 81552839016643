import { CSSProperties, memo } from 'react';
import { ColorPalette } from '../../../utils/colorPalette';

export type TextParagraphProps = {
  title: string;
} & Partial<CSSProperties>;

export const TextParagraph = memo(({ title, ...props }: TextParagraphProps) => {
  return (
    <div
      style={{
        color: ColorPalette.secondaryText,
        ...props,
      }}
    >
      <p>{title}</p>
    </div>
  );
});
