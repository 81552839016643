import { Table } from '../../shared/components/table/Table';
import { TableBody } from '../../shared/components/table/TableBody';
import { TableCell } from '../../shared/components/table/TableCell';
import { TableHeader } from '../../shared/components/table/TableHeader';
import { TableRow } from '../../shared/components/table/TableRow';
import { TextParagraph } from '../../shared/components/text/TextParagraph';
import { ColorPalette } from '../../utils/colorPalette';
import { Leaderboard } from './Game';

export type LeaderboardSectionProps = {
  leaderboard: Leaderboard;
  errorMessage: string;
};

export function LeaderboardSection({
  leaderboard,
  errorMessage,
}: LeaderboardSectionProps) {
  return leaderboard.length <= 0 ? (
    <TextParagraph title={errorMessage} />
  ) : (
    <Table>
      <TableHeader>
        <TableCell>
          <TextParagraph color={ColorPalette.primaryLight} title="Username" />
        </TableCell>
        <TableCell>
          <TextParagraph color={ColorPalette.primaryLight} title="Highscore" />
        </TableCell>
      </TableHeader>
      <TableBody>
        {leaderboard.map((player) => {
          return (
            <TableRow key={player._id}>
              <TableCell>
                <TextParagraph
                  color={ColorPalette.primaryText}
                  title={player.username ?? '-'}
                />
              </TableCell>
              <TableCell>
                <TextParagraph
                  color={ColorPalette.primaryText}
                  title={player.highScore?.toString() ?? '-'}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
