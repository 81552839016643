import { SectionPicker } from '../../shared/components/SectionPicker';
import { FormSectionType } from './FormSection';

export type FormSectionPickerProps = {
  setSection: (section: FormSectionType) => void;
};

export function FormSectionPicker({ setSection }: FormSectionPickerProps) {
  return (
    <SectionPicker
      sections={[
        {
          name: 'Register',
          setSection: setSection,
        },
        {
          name: 'Login',
          setSection: setSection,
        },
      ]}
    />
  );
}
