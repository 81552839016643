import { createContext, useContext, useState, PropsWithChildren } from 'react';

type UserContextProps = {
  username?: string;
  updateUserContext: (username: string | undefined) => void;
};

const userContext = createContext<UserContextProps | undefined>(undefined);

export function UserContextProvider({ children }: PropsWithChildren) {
  const [username, setUsername] = useState<string | undefined>(undefined);

  const updateUserContext = (updatedUser: string | undefined) => {
    setUsername(updatedUser);
  };

  return (
    <userContext.Provider value={{ username, updateUserContext }}>
      {children}
    </userContext.Provider>
  );
}

export function useUser() {
  if (!userContext) {
    throw Error('User not found!');
  }
  return useContext(userContext);
}
