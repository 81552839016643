import { CSSProperties, PropsWithChildren, memo } from 'react';

const gapValue = {
  1: '1vh',
  2: '4%',
  3: '8%',
  4: '16%',
  5: '20%',
  6: '24%',
} as const;

export type VStackProps = {
  gap: 1 | 2 | 3 | 4 | 5;
} & Partial<Omit<CSSProperties, 'gap'>>;

export const VStack = memo(
  ({ gap, children, ...props }: PropsWithChildren<VStackProps>) => {
    return (
      <div
        style={{
          ...props,
          gap: gapValue[gap],
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {children}
      </div>
    );
  }
);
