import { LoginForm } from '../../shared/components/forms/LoginForm';
import { RegisterForm } from '../../shared/components/forms/RegisterForm';
import { FormSectionType } from './FormSection';

export type FormSectionContentProps = {
  section: FormSectionType;
};

export function FormSectionContent({ section }: FormSectionContentProps) {
  if (section === 'Register') {
    return <RegisterForm />;
  }
  return <LoginForm />;
}
