import { Card } from '../shared/components/Card';
import { VStack } from '../shared/components/layout/VStack';
import { Game } from './Game/Game';

export function GamePage() {
  return (
    <Card marginTop={'3%'}>
      <VStack flexGrow={1} gap={1}>
        <Game />
      </VStack>
    </Card>
  );
}
