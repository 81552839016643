import { useEffect, useState } from 'react';
import { useUser } from '../../contexts/UserContext';
import { Button } from '../../shared/components/buttons/Button';
import { VStack } from '../../shared/components/layout/VStack';
import { TextHeader } from '../../shared/components/text/TextHeader';
import { TextParagraph } from '../../shared/components/text/TextParagraph';
import { ColorPalette } from '../../utils/colorPalette';
import * as api from '../../api/api';

export type GameOverWindowProps = {
  score: number;
  setScore: (newScore: number) => void;
  highScore: number;
  setHighScore: (newHighScore: number) => void;
  setGameHasStarted: (hasStarted: boolean) => void;
  setGameHasEnded: (hasEnded: boolean) => void;
};

export function GameOverWindow({
  score,
  highScore,
  setScore,
  setHighScore,
  setGameHasEnded,
}: GameOverWindowProps) {
  const userContext = useUser();
  const [errorMessage, setErrorMessage] = useState('');
  const [prevHighScore, setPrevHighScore] = useState<number>();

  useEffect(() => {
    const updateHighScore = async () => {
      try {
        const username = userContext?.username;
        if (!username) {
          throw Error('Something went wrong!');
        }
        if (score > highScore) {
          await api.updateUserHighScore(score, username);
          setHighScore(score);
        }
      } catch (err) {
        setErrorMessage(String(err));
      }
    };
    updateHighScore();
  }, [userContext, setHighScore]);

  useEffect(() => {
    if (!prevHighScore) {
      setPrevHighScore(highScore);
    }
  }, [setPrevHighScore]);

  const restartGame = () => {
    setScore(0);
    setGameHasEnded(false);
  };

  return (
    <VStack gap={2}>
      <TextHeader title="Wrong Answer!" />
      <TextParagraph
        color={ColorPalette.primaryText}
        title={`Your final score: ${score}`}
      />
      <TextParagraph
        color={ColorPalette.primaryText}
        title={`Your previous highscore: ${prevHighScore}`}
      />
      {score > (prevHighScore ?? 0) ? (
        <TextParagraph
          color={ColorPalette.primaryText}
          title={`Your new highscore: ${score}`}
        />
      ) : null}
      <Button onPress={restartGame} variant="secondary">
        <TextHeader color={ColorPalette.primaryLight} title="RESTART" />
      </Button>
      {errorMessage ? (
        <TextParagraph color={ColorPalette.primaryText} title={errorMessage} />
      ) : null}
    </VStack>
  );
}
